@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
  --golden-color: #1f4a4e;
  --footer-color: #000;
  --second-color: #424242;
  --hd-text-color: #fff;
  --second-color-opacity: rgba(0, 0, 0, 0.9);
  --card-bg: #f7f9fb;
  --bg-color: #d6dce1;
  --footer-hover-text: black;
  /* --bg-color: #fff; */
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll-container {
  height: 300px;
  overflow-y: scroll;
}

.scroll-container::-webkit-scrollbar {
  width: 3px;

}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #888;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scroll-container::-webkit-scrollbar-button {
  display: none;
}