.main-footer {
    background-color: var(--footer-color);
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.upper-footer {
    width: 100%;
    min-height: 270px;
    position: relative;
}

.upper-footer:hover::before {
    background-image: url('../../assets/footer-logo-3.png');
}

.upper-footer::before {
    content: '';
    position: absolute;
    right: 25px;
    bottom: 0;
    top: -110px;
    width: 30%;
    height: 150%;
    background-image: url('../../assets/footer-logo-2.png');
    background-size: 100% 100%;
    transition: 0.5s;
    z-index: 1;
    /* z-index: 0; */
}

/* animation video code start from here*/

.background-video-div {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    overflow: hidden;
}

.background-video-div>video {
    width: 100%;
    height: auto;
}

/* animation video code end here*/

.footer-container {
    display: flex;
    z-index: 999;
    flex-wrap: wrap;
}

.sub-footer {
    width: 300px;
    height: 290px;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 9;
}

.logo-div {
    width: 100%;
    min-height: 100px;
}

.footer-logo-div {
    margin-top: 23px;
}

.logo-div h1 {
    font-size: 40px;
    color: white;
    padding: 30px;
}

.logo-div h1 span {
    border-bottom: 1px solid var(--golden-color);
}

.text-div {
    width: 100%;
    height: 250px;
    color: var(--golden-color);
    font-size: 15px;
}

.text-div-link {
    text-decoration: none;
    color: white;
    font-size: 16px;
}

.text-div div p {
    font-weight: 600;
    width: 70%;
}

.text-div div {
    font-weight: 500;
    display: flex;
    align-items: center;
}

.text-div div {
    cursor: pointer;
}

.text-div div:hover .text-div-link {
    color: var(--footer-hover-text);
}

.text-div div:hover .icon-div-footer {
    color: var(--footer-hover-text);
    background-color: white;
}

.social-media-icon-div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.icon-div-footer {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    background-color: var(--golden-color);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.location-icon {
    font-size: 25px;
}

.quick-links-div {
    border: none;
}

.footer-link-div {
    color: white;
    padding: 0;
    padding-left: 30px;
}

.footer-link-div>ul {
    list-style-type: none !important;
    padding: 0;
}

.footer-list-icons {
    margin-right: 20px;
}

.footer-link-div>ul>li {
    font-size: 16px;
    margin-top: 10px;
}

.footer-links {
    text-decoration: none;
    color: white;
}

.footer-links:hover {
    color: var(--footer-hover-text);


}

.footer-icons-div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: white;
    background-color: var(--golden-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.footer-icons-div:hover {
    color: var(--golden-color) !important;
    background-color: white;
}

.lower-footer {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--golden-color);
    color: white;
    font-size: 14px;
}

.footer-logo {
    width: 250px;
}

@media screen and (max-width: 1024px) {
    .footer-container {
        display: flex;
    }

    .sub-footer {
        border: none;
        min-height: auto;
    }

    .sub-footer-service {
        display: none;
    }

    .logo-div {
        height: auto;
    }

    .logo-div h1 {
        padding: 10px;
    }

    .text-div {
        padding-top: 0;
        min-height: auto;
    }

    .upper-footer::before {
        width: 30%;
        height: 80%;
    }
}

@media screen and (max-width: 968px) {
    .background-video-div>video {
        width: auto;
        height: 100%;
    }
}
@media screen and (max-width: 912px) {
    .upper-footer::before {
        width: 30%;
        height: 48%;
    }
}

@media screen and (max-width: 912px) {
    .upper-footer::before {
        width: 30%;
        height: 35%;
    }
}

@media screen and (max-width: 480px) {
    .upper-footer::before {
        top: -120px;
        width: 42%;
        height: 30%;
    }
}


@media screen and (max-width: 414px) {
    .upper-footer::before {
        top: -120px;
        width: 44%;
        height: 25%;
    }
}

@media screen and (max-width: 360px) {
    .footer-input {
        width: 90% !important;
    }

    .logo-div h1 {
        font-size: 30px;
    }

    .logo-div h1 {
        padding: 0px;
    }
}