.service-page-img-div {
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
    background-image: url('../../assets/Service/service-pages-header.jpg');
    background-size: cover;
    display: flex;
    align-items: center;
}

.shape-svg-container {
    width: 100%;
    position: absolute;
    bottom: -1px;
    z-index: 1;
    color: white;
    pointer-events: none;
}

.shape-svg-container>svg {
    height: 66px;
    width: 100%;
}

.service-page-main-hd {
    color: #fff;
    font-weight: 700;
    font-size: 78px;
}

.service-page-card-div {
    width: 100%;
}

.sub-service-page-card-div {
    width: 100%;
    margin-top: 200px;
}

.service-page-card-img-div {
    width: 40%;
    height: 550px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
}

.service-page-card-text-div {
    width: 60%;
    padding: 100px;
}

.service-page-card-text-div-middle {
    padding-left: 0px;
}

.service-page-card-text-div>h1 {
    color: #091D3E;
    font-size: 45px;
    font-weight: 700;
}

.service-page-card-text-div>h1>span {
    color: var(--golden-color);
}

.service-page-card-text-div>h2 {
    border-color: #088BED;
    border-style: solid;
    border-width: 0px 0px 0px 5px;
    font-size: 25px;
    padding: 0px 65px 0px 28px;
    margin: -4px 0px 0px 3px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.service-page-card-text-div>p {
    font-size: 20px;
}

.service-page-card-img {
    width: 100%;
    height: 100%;
}

.img-div-service {
    width: 50%;
    height: 100%;
}

.img-service {
    width: 100%;
    height: 100%;
}

.counter-div-service-page {
    padding: 70px 0px 70px 0px;
    width: 100%;
}




@media screen and (max-width: 768px) {
    .service-page-img-div {
        height: 330px;
    }

    .sub-service-page-card-div {
        flex-direction: column;
    }

    .service-page-card-img-div {
        width: 100%;
    }

    .service-page-card-text-div {
        padding: 0;
        width: 100%;
    }

    .sub-service-page-card-div {
        margin-top: 30px;
    }

    .mt-100px {
        margin-top: 100px;
    }

    .service-page-main-hd {
        font-size: 48px;
    }

    .service-page-card-img-div {
        height: 350px;
    }
}