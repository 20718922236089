.main-navbar {
    height: 80px;
    background-color: var(--bg-color) !important;
}

.nav-links {
    font-weight: 600 !important;
    font-size: 18px !important;
}

.nav-links:hover {
    border-bottom: 2px solid var(--golden-color);
    color: var(--golden-color) !important;
    margin-bottom: -1px;
}

.nav-active-link {
    color: var(--golden-color) !important;
}

.nav-logo-img {
    width: 200px;
}

@media screen and (max-width:768px) {
    .nav-logo-img {
        width: 165px;
    }
}

@media screen and (max-width:575px) {
    .nav-img-div {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .main-navbar {
        height: auto;
        padding-top: 0px !important;
        padding-bottom: 10px !important;
    }

    .nav-logo-img {
        width: 130px;
    }
}