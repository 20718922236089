.main-home-div{
    position: relative;
}

.sub-home-div{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: -1111;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.background-image{
    width: 60%;
}