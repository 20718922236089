.not-found-main {
    width: 100%;
    min-height: 60vh;
}

.notfound-div {
    width: 100%;
}

.notfound-div h1 {
    color: #091d3e;
    font-size: 40px;
    font-weight: 700;
}

.notfound-div p {
    font-size: 16px;
}