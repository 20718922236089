.job-page-container {
    width: 100%;
    min-height: 100vh;
}

.hero-section-div {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 100px;
}

.hero-text-div {
    height: 70vh;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.hero-img-div {
    height: 70vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.hero-text-div>div>h1 {
    font-weight: bold;
    font-size: 50px;
    text-transform: uppercase;
    line-height: 1.2;
    margin-top: 0px;
}

.hero-text-div>div>h1>span {
    color: var(--golden-color);
}

.hero-text-div>div>p {
    color: #2424248c;
}

.hero-img-div>img {
    height: 590px;
}

.jobs-main-div {
    width: 100%;
    min-height: 50vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 100px;
}

.job-card-div {
    width: 22%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0px 1px 3px -1px;
    position: relative;
    z-index: 9;
    padding: 16px;
    text-transform: capitalize;
    transition: 0.5s;
    margin-top: 70px;
}

.job-card-div::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    border-radius: 15px;
    transition: 0.5s;
    z-index: -1;
    /* background-color: #CFB53B; */
    background-color: var(--bg-color);
}

.job-card-div:hover::before {
    width: 100%;
}

.job-card-div:hover {
    margin-bottom: 10px;
}

.job-hd {
    font-size: 35px;
    font-weight: 400;
    text-transform: uppercase;
}

.sub-job-hd {
    font-size: 32px;
    font-weight: 400;
    color: var(--golden-color);
}

.job-dis {
    font-size: 18px;
    color: #2424248c;
}

.job-card-div:hover .job-hd,
.job-card-div:hover .sub-job-hd,
.job-card-div:hover .job-dis {
    color: black !important;
}

.job-num-div {
    position: absolute;
    right: -10px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-size: 50px;
    background: linear-gradient(45deg, #035B59 36%, #05afac);
    box-shadow: 0px 0px 7px 2px grey;
    top: -40px;
    transition: 0.5s;
}

@media screen and (max-width: 992px) {
    .hero-section-div {
        display: flex;
        margin-top: 0px;
        flex-direction: column;
    }

    .hero-text-div {
        height: auto;
    }

    .hero-text-div>div>h1 {
        font-weight: bold;
        font-size: 40px;
    }

    .hero-img-div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .job-card-div {
        width: 47%;
    }
}

@media screen and (max-width: 912px) {
    .hero-text-div {
        height: auto;
    }

    .hero-text-div>div>h1 {
        font-weight: bold;
        font-size: 35px;
        text-align: center;
    }

    .hero-text-div>div>p {
        font-size: 15px;
        text-align: center;
    }

    .hero-img-div>img {
        height: 450px;
    }

    .hero-img-div {
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    .job-card-div {
        width: 100%;
    }
}