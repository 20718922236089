.carousel-div {
    width: 100%;
    height: auto;
    position: relative;
    background-color: white;
}

.carousel-div>video {
    width: 100%;
    height: 100%;
}